import kababiLogo from './images/logo.png';
import './App.css';

import ResizeImage from 'react-resize-image'

function App() {
  return (
    <div className="App">
      <div className="Header">
        <img src={kababiLogo} className="Logo" />
        <h1 className="Note">Launching Website SOON!</h1>
      </div>

      <div className="orderUs">
        <p className="orderUsText">To order Call or WhatsApp us at:</p>
        <a href="https://api.whatsapp.com/send?phone=923333470387" className="number" target="_blank">0333-3470387</a>
      </div>
    </div>
  );
}

export default App;
